@use '@angular/material' as mat;
@import '../../../assets/scss/settings';

.mat-form-field--datepicker-dark {

  .mat-datepicker-toggle-active {
    color: mat.get-color-from-palette($et-primary, 700);
  }
  .mat-form-field__placeholder {
    margin-left: var(--spacing-8);
    margin-right: var(--spacing-4);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-14);
    line-height: 15px;
    color: mat.get-color-from-palette($et-primary, 300);
  }

  .mat-form-field-infix{
    padding: 0;
    border: 0;
    display: flex;
    align-items: center;
  }

  .mat-form-field-prefix .mat-icon-button{
    width: 20px !important;
    height: 16px !important;
    border-radius: 0 !important;
    .mat-icon{
      height: 18px !important;
      width: 16px !important;
      margin: 0 !important;
    }
  }
}

.datetime-range-picker--is-disabled{
  .mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex{
    background: mat.get-color-from-palette($et-primary, 50);
  }
}
