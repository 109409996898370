@use '@angular/material' as mat;
@import '../../assets/scss/settings';
.statistics{
  mat-tab-header{
    background: mat.get-color-from-palette($et-primary, 50);
    padding: 0 var(--spacing-32);
  }
  et-filters{
    .filters{
      justify-content: flex-end;
    }
  }
}
