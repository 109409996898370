@use '@angular/material' as mat;
@import '../../../assets/scss/settings';
.filterable-table {
  .mat-paginator-container {
    min-height: unset;
    padding: 0;
  }
  .mat-paginator-range-actions {
    .mat-paginator-range-label {
      font-size: var(--font-size-14);
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.02em;
      color: mat.get-color-from-palette($et-primary, 600);
      margin: 0 var(--spacing-16) 0 var(--spacing-8);
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: unset;
      height: unset;
      .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    button[disabled] {
      pointer-events: none;
    }
  }
  .mat-table {
    width: 100%;
    th.mat-header-cell {
      border-bottom-color: mat.get-color-from-palette($et-primary, 100);
    }
    tr.mat-header-row, tr.mat-row {
      height: 50px;
    }
    th {
      font-weight: var(--font-weight-bold);
      font-size: var(--font-size-16);
      line-height: 24px;
      color: $labels-color;
    }
    td {
      letter-spacing: 0.02em;
      color: mat.get-color-from-palette($et-primary, 700);
      font-size: var(--font-size-16);
      line-height: 21px;
      padding-right: var(--spacing-16);
      max-width: 400px;
      &.mat-cell {
        border-bottom-color: mat.get-color-from-palette($et-primary, 100);
      }
    }
    tbody {
      tr {
        .two-text-cell{
          display: flex;
          flex-direction: column;
          span:last-child{
            font-size: 14px;
            color: $labels-color;
          }
        }
        .link-cell{
          display: flex;
          align-items: center;
          .link-cell-icon{
            color: $link-color;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $link-color;
            border-radius: $border-radius-small;
          }
          mat-icon + a {
            margin-left: var(--spacing-8);
          }
          a{
            text-decoration: underline;
          }
          p{
            cursor: not-allowed;
          }
        }
        .editable-cell {
          .mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper {
            padding-bottom: 0;
          }
          .mat-form-field.mat-form-field-appearance-outline {
            width: unset !important;
          }
          .mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
            padding: 0.3rem 0;
          }
          ::-moz-selection { /* Firefox */
            background: mat.get-color-from-palette($et-primary, 400);
          }
          ::selection {
            background: mat.get-color-from-palette($et-primary, 400);;
          }
        }
        .file-type-cell {
          display: flex;
          align-items: center;
        }
        .image-type-cell {
          img {
            height: 35px;
          }
        }
        .actions-container-cell {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .validate-single-field{
            padding-right: 1rem;
          }
        }
      }
      tr.cursor-pointer:hover {
        background-color: mat.get-color-from-palette($et-primary, 50);
      }
      tr:last-of-type td.mat-cell {
        border-bottom-color: transparent;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}
