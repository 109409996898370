@mixin square($size) {
  width: $size;
  min-width: $size;
  max-width: $size;
  height: $size;
  min-height: $size;
  max-height: $size;
}

@mixin thumbnail($size) {
  @include square($size);
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  background-repeat: no-repeat;
}
