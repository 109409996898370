@use '@angular/material' as mat;
@import '../../assets/scss/settings';
.institution-details {
  mat-tab-header {
    background: mat.get-color-from-palette($et-primary, 50);
    padding: 0 var(--spacing-40);
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  }
  .institution-details__time-machine-stripe {
    mat-datepicker-toggle {
      button {
        width: 32px;
        height: 32px;
        line-height: 32px;
      }
    }
  }
}
