@use '@angular/material' as mat;
@import "settings";
@import "mixins";
//animation keyframes
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideRight {
  0% {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes slideLeft {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }
}
//Base CSS setup
* {
  box-sizing: border-box;
  margin: 0;
  &:focus {
    outline: none; // remove chrome blue outline
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  // do not forget to escape the variables :)
  --spacing-2: #{$spacing-2};
  --spacing-4: #{$spacing-4};
  --spacing-8: #{$spacing-8};
  --spacing-10: #{$spacing-10};
  --spacing-12: #{$spacing-12};
  --spacing-16: #{$spacing-16};
  --spacing-20: #{$spacing-20};
  --spacing-24: #{$spacing-24};
  --spacing-32: #{$spacing-32};
  --spacing-40: #{$spacing-40};
  --spacing-48: #{$spacing-48};
  --spacing-56: #{$spacing-56};
  --spacing-64: #{$spacing-64};
  --spacing-72: #{$spacing-72};
  --spacing-84: #{$spacing-84};
  --spacing-96: #{$spacing-96};
  --spacing-128: #{$spacing-128};
  --spacing-192: #{$spacing-192};
  --spacing-256: #{$spacing-256};
  --et-border: #{$et-border};
  --et-border-bold: #{$et-border-bold};
  --border-radius-smallest: #{$border-radius-smallest};
  --border-radius-small: #{$border-radius-small};
  --border-radius-medium: #{$border-radius-medium};
  --border-radius-big: #{$border-radius-big};
  --border-radius-bigger: #{$border-radius-bigger};
  --border-radius-biggest: #{$border-radius-biggest};
  --font-size-12: #{$font-size-12};
  --font-size-14: #{$font-size-14};
  --font-size-16: #{$font-size-16};
  --font-size-18: #{$font-size-18};
  --font-size-21: #{$font-size-21};
  --font-size-24: #{$font-size-24};
  --font-weight-regular: #{$font-weight-regular};
  --font-weight-medium: #{$font-weight-medium};
  --font-weight-bold: #{$font-weight-bold};
  --modal-width: #{$modal-width};
  --modal-width-fixed-sm: #{$modal-width-fixed-sm};
  --modal-height: #{$modal-height};
  --modal-height-fixed-sm: #{$modal-height-fixed-sm};
  --modal-header-height: #{$modal-header-height};
  --modal-footer-height: #{$modal-footer-height};
  --header-height: #{$header-height};
  --max-form-width: #{$max-form-width};
  //base font size must be defined here as per custom variables declared in root element.
  font-size: 16px;
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
  @media screen and (min-width: $layout-breakpoint-xl) {
    font-size: 17px;
  }
  @media screen and (min-width: $layout-breakpoint-xxl) {
    font-size: 18px;
  }
}
html, body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  overflow-x: auto;
  overflow-y: hidden;
}
h1, h2 {
  letter-spacing: 0.01em;
  color: mat.get-color-from-palette($et-primary, 500);
  font-weight: var(--font-weight-medium);
}
//big headings
h1 {
  font-size: var(--font-size-24);
}
h2 {
  font-size: var(--font-size-21);
}
h3 {
  font-size: var(--font-size-16);
}
//small headings
h4 {
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-14);
}
h5 {
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-12);
}
p, li {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-regular);
  color: mat.get-color-from-palette($et-primary, 500);
  line-height: 1.45;
  margin: 0;
}
small {
  display: inline-block;
  font-size: var(--font-size-12);
  font-weight: var(--font-weight-regular);
}
a {
  cursor: pointer;
  text-decoration: none;
  color: $link-color;
  font-size: var(--font-size-14);
  &:visited, &:active {
    color: $link-color;
  }
}
strong {
  font-weight: var(--font-weight-medium);
}
label {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-regular);
  color: $labels-color;
  &:not(.mat-radio-label) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
/**                           **/
/** OVERRIDDEN MATERIAL STUFF **/
/**                           **/
//all buttons default appearance
button.mat-button, button.mat-stroked-button, button.mat-flat-button {
  border-radius: $border-radius-small;
  padding: 0 var(--spacing-12);
  font-size: var(--font-size-14);
  color: mat.get-color-from-palette($et-primary, 600);
  line-height: 32px;
  &.big-button {
    padding: 0 var(--spacing-16);
    font-size: var(--font-size-16);
    line-height: 42px;
  }
  &.only-icon {
    padding: 0 2px;
    min-width: 38px;
  }
  mat-icon {
    font-size: 22px !important;
  }
}
//stroked button - the primary button in Entity
button.mat-stroked-button {
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.12);
  transition: background .6s ease, border .2s ease, box-shadow .2s ease, padding .2s ease, transform .2s ease;
  background: $white-color;
  &:hover:not(.mat-button-disabled) {
    border-color: mat.get-color-from-palette($et-primary, 600);
    box-shadow: none;
    transform: translateY(2px);
  }
  &.mat-button-disabled {
    box-shadow: none;
    color: mat.get-color-from-palette($et-primary, 100);
  }
  &.tooltip-button {
    background-color: $white-color;
    padding: 0;
    min-width: 0;
    width: 28px;
    height: 28px;
    flex-shrink: 0;
    line-height: 2px;
    border-radius: 50%;
    margin-left: var(--spacing-10);
  }
}
//flat button - the blacky one
button.mat-flat-button {
  border: 1px solid mat.get-color-from-palette($et-primary, 500);
  box-sizing: border-box;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.12);
  transition: background .6s ease, border .2s ease, box-shadow .2s ease, padding .2s ease, transform .2s ease;
  &:hover:not(.mat-button-disabled) {
    box-shadow: none;
    transform: translateY(2px);
  }
  &.mat-button-disabled {
    box-shadow: none;
    color: mat.get-color-from-palette($et-primary, 100);
    border: none;
  }
}
.mat-simple-snackbar-action button {
  max-height: unset !important;
  color: $white-color;
}
//all input fields
.mat-form-field.mat-form-field-appearance-outline {
  border-radius: var(--border-radius-small);
  &:not(.mat-focused):not(.mat-form-field-invalid) {
    .mat-form-field-outline-end,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-start {
      border-color: mat.get-color-from-palette($et-primary, 100);
    }
  }
  &.mat-focused:not(.mat-form-field-invalid) {
    .mat-form-field-outline-end,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-start {
      border-color: mat.get-color-from-palette($et-primary, 500);
    }
  }
  &:not(.mat-form-field-disabled):not(.mat-form-field-invalid) {
    .mat-form-field-flex:hover {
      .mat-form-field-outline-end,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-start {
        border-color: mat.get-color-from-palette($et-primary, 500) !important;
      }
    }
  }
  .mat-form-field-wrapper {
    padding-bottom: var(--spacing-24);
    margin: 0;
    .mat-form-field-flex {
      background: $white-color;
      margin-top: 0;
      .mat-form-field-infix {
        padding: 0.7rem 0;
        border: 0;
        display: flex;
        align-items: center;
        .mat-input-element {
          color: mat.get-color-from-palette($et-primary, 700);
          line-height: 1.375;
          margin: 0;
          &::placeholder {
            color: mat.get-color-from-palette($et-primary, 300);
          }
          &:disabled {
            color: rgba(0, 0, 0, 0.38);
          }
        }
      }
      .mat-form-field-outline {
        top: 0;
      }
      .mat-form-field-outline-end,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-start {
        border-width: 1px;
      }
      .mat-form-field-outline-end {
        border-radius: 0 $border-radius-smallest $border-radius-smallest 0;
      }
      .mat-form-field-outline-start {
        min-width: 24px;
        border-radius: $border-radius-smallest 0 0 $border-radius-smallest;
      }
      .mat-form-field-prefix, .mat-form-field-suffix {
        top: 0;
        mat-icon {
          @include square(16px);
          line-height: 20px;
          font-size: var(--font-size-16);
          margin-left: -.125rem;
          margin-right: .25rem;
          color: mat.get-color-from-palette($et-primary, 800);
        }
        i {
          font-size: var(--font-size-18);
        }
      }
      .mat-form-field-suffix {
        top: 3.5px;
      }
    }
    //needed for mat-error
    .mat-form-field-subscript-wrapper {
      margin-top: var(--spacing-4);
      font-size: var(--font-size-14);
      padding: 0;
      top: auto;
    }
    .mat-form-field-label-wrapper {
      display: none;
      padding: 0;
      position: unset;
      left: unset;
    }
    .mat-form-field-label {
      top: unset;
      position: unset;
      left: unset;
      margin-bottom: 0
    }
  }
  &.big-space-bottom {
    .mat-form-field-wrapper {
      padding-bottom: var(--spacing-40) !important;
    }
  }
}
.mat-hint.custom-validation-error {
  color: mat.get-color-from-palette($et-error, 500);
  white-space: normal;
  font-size: var(--font-size-14);
  text-align: left;
}
//radio buttons
.mat-radio-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  //max-width: $max-form-width;
  padding-bottom: var(--spacing-24);
  .mat-error {
    font-size: var(--font-size-14);
  }
}
.mat-radio-button {
  //max-width: $max-form-width;
  border: var(--et-border);
  margin-bottom: var(--spacing-8);
  border-radius: var(--border-radius-smallest);
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
  &.mat-radio-checked {
  }
}
.mat-radio-group.sf-radio-group-horizontal {
  display: grid;
  grid-gap: var(--spacing-12);
  grid-template-columns: 1fr 1fr;
  &--grouped-by-three {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .mat-radio-button {
    flex: 1;
  }
}
.mat-calendar-body-selected {
  background-color: mat.get-color-from-palette($et-primary, 700);
}
.mat-radio-label {
  margin: 0;
  padding: var(--spacing-12);
}
.mat-radio-outer-circle {
  width: 18px !important;
  height: 18px !important;
  border: var(--et-border);
  background: $white-color !important;
}
.mat-radio-inner-circle {
  width: 18px !important;
  height: 18px !important;
}
.mat-radio-label-content {
  font-size: var(--font-size-16);
  white-space: normal;
}
.mat-radio-button .mat-radio-ripple {
  left: calc(50% - 15px) !important;
  top: calc(50% - 15px) !important;
  height: 28px !important;
  width: 27px !important;
}
//selects
.mat-select .mat-select-arrow {
}
.mat-select:not(.mat-select-disabled) .mat-select-value {
  line-height: 1.375;
}
.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: none !important;
}
// multiselect list
.mat-selection-list {
  width: 100%;
  //max-width: $max-form-width;
  padding-bottom: var(--spacing-24);
}
.mat-list-option {
  border: var(--et-border);
  margin-bottom: var(--spacing-8);
  border-radius: var(--border-radius-smallest);
  height: auto !important;
  min-height: 48px !important;
  display: flex !important;
  padding: 8px 0 !important;
  &[aria-selected="true"] {
  }
}
.mat-pseudo-checkbox {
  border: var(--et-border) !important;
  &.mat-pseudo-checkbox-checked {
    &::after {
      top: 2.6px;
      left: 2px;
    }
  }
}
//single checkbox
.mat-checkbox-layout {
  margin: 0;
  align-items: flex-start !important;
  .mat-checkbox-inner-container {
    margin-top: 2px;
    @include square(18px);
    .mat-ripple {
      height: 18px;
      width: 18px;
      left: calc(50% - 9px);
      top: calc(50% - 9px);
    }
  }
  .mat-checkbox-label {
    white-space: normal;
    line-height: 21px;
  }
  .mat-checkbox-frame {
  }
}
//mat option
.mat-select-panel {
  .mat-option {
    height: 2.5rem !important;
    line-height: 2.5rem !important;
    .mat-option-text {
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-14);
      line-height: 20px;
    }
    &:not(.mat-option-selected) {
    }
  }
}
//slide toggle
.mat-slide-toggle {
  margin-bottom: var(--spacing-24);
}
//icon button
.mat-icon-button.mat-icon-button--small {
  @include square(14px);
  display: flex;
  justify-content: center;
  align-items: center;
  .mat-button-wrapper,
  .mat-icon {
    display: flex;
    justify-content: center;
    @include square(14px);
  }
}
//paginator
.mat-paginator {
  background: transparent;
}
//tab panel
.mat-tab-label, .mat-tab-link {
  padding: 0 var(--spacing-16) !important;
  min-width: unset !important;
  font-size: var(--font-size-16);
  color: $labels-color;
  opacity: 1 !important;
  &.mat-tab-label-active {
    color: mat.get-color-from-palette($et-primary, 500);
  }
}
.mat-form-field--et-datepicker {
  .mat-form-field-wrapper {
    padding: 0 !important;
  }
  .mat-form-field-flex {
    background: $white-color !important;
    padding: 0 !important;
    align-items: center;
    border-radius: $border-radius-small !important;
  }
  .mat-datepicker-toggle-active {
    color: mat.get-color-from-palette($et-primary, 600);
  }
  .mat-form-field-infix {
    padding: 0 !important;
    border: 0 !important;
    display: flex;
    align-items: center;
    max-width: 110px;
  }
  .mat-icon-button {
    height: 2rem !important;
  }
}
/**                                             **/
/**         auxiliary reusable styles           **/
/**                                             **/
.hide-from-1024 {
  display: none !important;
  @media (max-width: $layout-breakpoint-md-minus-1) {
    display: inherit !important;
  }
}
.hide-until-1023 {
  @media (max-width: $layout-breakpoint-md-minus-1) {
    display: none !important;
  }
}
.spacing-top-40 {
  margin-top: var(--spacing-40);
}
.spacing-bottom-40 {
  margin-bottom: var(--spacing-40);
}
.button-spacing-right-8 {
  margin-right: var(--spacing-8) !important;
}
.cursor-pointer {
  cursor: pointer;
  pointer-events: all;
}
.no-cursor-pointer {
  cursor: none;
  pointer-events: none;
  color: mat.get-color-from-palette($et-primary, 100);
}
.user-select-none { // when you click you do not select the whole thing
  user-select: none;
}
.label-hint {
  font-size: var(--font-size-12);
  color: $labels-color;
}
label + .label-hint {
  margin-bottom: var(--spacing-4);
  margin-top: calc(var(--spacing-4) * -1);
}
.divider {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: var(--et-border);
}
.router-wrapper > *:not(router-outlet) {
  flex-grow: 1;
  width: 100%;
}
//general form fields containers
.form-field-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form-fields-grid-50 {
  display: grid;
  grid-template-columns: repeat(2, calc((#{$max-form-width} - var(--spacing-8)) / 2));
  grid-gap: var(--spacing-8);
}
.form-fields-grid-60 {
  display: grid;
  grid-template-columns: 232px 127px;
  grid-gap: var(--spacing-8);
}
.form-fields-grid-70 {
  display: grid;
  grid-template-columns: 280px 80px;
  grid-gap: var(--spacing-8);
}
.full-width-field {
  width: 100%;
}
.versioned-field{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .info-overlay{
    width: 150px;
  }
}
.form-box {
  margin-top: var(--spacing-20);
  border-radius: $border-radius-small;
  border: 1px solid mat.get-color-from-palette($et-primary, 100);
  max-width: 50vw;
  .mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  & > * {
    border-top-left-radius: $border-radius-small;
    border-top-right-radius: $border-radius-small;
    padding: var(--spacing-16) var(--spacing-20);
  }
  & > div {
    display: flex;
    align-items: center;
    //&:not(:last-child) {
    //  margin-bottom: var(--spacing-24);
    //}
    label {
      min-width: 210px;
      max-width: 210px;
      white-space: pre-line;
      @media (max-width: $layout-breakpoint-form-readability) {
        min-width: 165px;
        max-width: 165px;
      }
    }
    p {
      white-space: pre-line;
    }
    a {
      font-size: var(--font-size-16);
    }
  }
  .field-with-actions-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .attachment-tooltip{
      font-size: var(--font-size-14);
    }
  }
  &__admin-field{
    &:hover{
      background-color: mat.get-color-from-palette($et-primary, 50);
      et-admin-unapprove-field{
        visibility: visible;
      }
    }
  }
  &__vfield-not-valid {
    label {
      color: mat.get-color-from-palette($et-error, 500);
    }
  }
  .text-area-view{
    white-space: pre-line;
  }
  .validate-single-field{
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  &--is-admin {
    .form-box__vfield-not-valid {
      background-color: mat.get-color-from-palette($et-error, 50);
    }
  }
}
.list-box {
  border-radius: $border-radius-small;
  border: 1px solid mat.get-color-from-palette($et-primary, 100);
  &__header {
    display: grid;
    grid-template-columns: 2fr minmax(150px, 1fr) minmax(100px, 1fr);
    grid-column-gap: var(--spacing-20);
    padding: var(--spacing-12) var(--spacing-16);
    border-bottom: 1px solid mat.get-color-from-palette($et-primary, 100);
    h3 {
      color: $labels-color;
      display: flex;
      align-items: center;
      mat-icon {
        margin-left: -8px;
        cursor: pointer;
      }
    }
    .sort-selected {
      color: mat.get-color-from-palette($et-primary, 500);
      mat-icon {
        color: mat.get-color-from-palette($et-primary, 500);
      }
    }
  }
  &__row {
    display: grid;
    grid-template-columns: 2fr minmax(150px, 1fr) minmax(100px, 1fr);
    grid-column-gap: var(--spacing-20);
    padding: var(--spacing-12) var(--spacing-20);
    &:not(:last-of-type) {
      border-bottom: 1px solid mat.get-color-from-palette($et-primary, 100);
    }
    & > div {
      display: flex;
      align-items: center;
      mat-checkbox {
        margin-right: var(--spacing-16);
      }
    }
    & > a{
      font-size: var(--font-size-16);
    }
    & > p {
      display: flex;
      align-items: center;
    }
    & > p:first-child {
      color: $labels-color;
      font-weight: var(--font-weight-bold);
    }
  }
  &__no-data {
    padding: var(--spacing-12) var(--spacing-16);
    text-align: center;
  }
  &.category-table {
    display: grid;
    grid-template-columns: 1fr 4fr;
    margin-top: var(--spacing-20);
    .category-axis {
      p {
        color: $labels-color;
        padding: var(--spacing-12) var(--spacing-20);
        white-space: nowrap;
        &:not(:last-child) {
          border-bottom: 1px solid mat.get-color-from-palette($et-primary, 100);
        }
      }
    }
    .data-axis {
      p {
      }
      & > div {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        padding: var(--spacing-12) 0;
      }
      & > div:not(:last-child) {
        border-bottom: 1px solid mat.get-color-from-palette($et-primary, 100);
      }
      & > div:first-child p {
        color: $labels-color;
        font-weight: var(--font-weight-bold);
      }
    }
  }
}
.file-view {
  display: flex;
  align-items: center;
  span {
    cursor: pointer;
    border: 1px solid $link-color;
    color: $link-color;
    border-radius: $border-radius-small;
    font-size: 8px;
    font-weight: var(--font-weight-bold);
    padding: 6px var(--spacing-4);
  }
  a {
    margin-left: var(--spacing-10);
    text-decoration: underline;
  }
}
.text-list-view {
  & > div:not(:last-child) {
    margin-bottom: var(--spacing-16);
  }
  a {
    text-decoration: underline;
    font-size: var(--font-size-16);
  }
}
.details-side-panel {
  top: 0;
  right: -50vw;
  position: fixed;
  z-index: 1000;
  width: 50vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  opacity: 0;
  overflow-y: auto;
  background-color: $white-color;
  padding: var(--spacing-24) var(--spacing-40);
  &--visible {
    opacity: 1;
    transform: translate(-50vw, 0px);
    transition: transform 0.5s;
  }
  &__header {
    padding-bottom: var(--spacing-32);
  }
  &__content {
    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        margin-left: var(--spacing-10);
      }
    }
  }
  .temporal-list-section {
    margin-top: var(--spacing-40);
    & > h2{
      margin-bottom: var(--spacing-20);
    }
    &__add-new {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: var(--spacing-20);
    }
    .list-box__row {
      grid-template-columns: minmax(100px, 1fr) minmax(150px, 1fr) minmax(100px, 1fr) auto;
    }
    .actions-container-cell {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  @media (max-width: $layout-breakpoint-form-readability) {
    right: -70vw;
    width: 70vw;
    &.details-side-panel--visible {
      transform: translate(-70vw, 0px);
    }
    .form-box {
      max-width: 100%;
    }
  }
}
/**                                  **/
/**    ABSTRACT CUSTOM COMPONENTS    **/
/**                                  **/
//BACK ARROW FOR BACK ROUTING
.back-arrow {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-16);
  line-height: 20px;
  user-select: none;
  span {
    user-select: none;
    font-size: 80%;
  }
}
//MODAL OVERLAY
.base-modal-container, .base-modal-container-fixed-small {
  overflow-y: hidden;
  border-radius: var(--border-radius-medium);
  .mat-dialog-container {
    border-radius: var(--border-radius-medium);
    padding: 0 !important;
    box-shadow: 0 4px 16px rgba(0, 33, 89, 0.16), 0 2px 2px rgba(0, 33, 89, 0.08);
  }
}
//MODAL OVERLAY FULLSCREEN
.base-modal-container-fullscreen {
  overflow-y: hidden;
  border-radius: 0;
  .mat-dialog-container {
    border-radius: 0;
    padding: 0 !important;
  }
}
//BASE MODAL - this is an abstract style which can be applied to any kind of modal, not only BaseModal. That's why lays here.
.base-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $white-color;
  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: var(--spacing-16);
    border-bottom: var(--et-border);
    border-top-left-radius: var(--border-radius-medium);
    border-top-right-radius: var(--border-radius-medium);
    &--sticky {
      position: fixed;
      z-index: 10;
      background: $white-color;
      height: var(--modal-header-height);
      width: var(--modal-width);
      padding: 0 var(--spacing-16);
    }
    &__title {
      flex: 1;
      display: flex;
      align-items: center;
      margin: 0;
      //font-size: var(--font-size-24);
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-18);
      img {
        width: 1.125rem;
        margin-right: var(--spacing-16);
      }
    }
    & > mat-icon {
      @include square(18px);
      color: mat.get-color-from-palette($et-primary, 300);
    }
  }
  &__body {
    overflow-y: auto;
    height: 100%;
    padding: var(--spacing-16);
    //min-height: 74px;
    //background-color: mat-color($et-primary, 50);
    &--with-sticky-header {
      margin: var(--modal-header-height) 0 0 0;
    }
    &--with-sticky-footer {
      height: calc(var(--modal-height) - var(--modal-header-height) - var(--modal-footer-height));
    }
    &--with-sticky-footer-no-header {
      height: calc(var(--modal-height) - var(--modal-footer-height));
    }
    &--no-header {
      margin: 0;
    }
    p {
      font-size: var(--font-size-14);
      color: mat.get-color-from-palette($et-primary, 600);
    }
  }
  &__body-html {
    margin-bottom: var(--spacing-4);
  }
  &__fields-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: var(--spacing-8);
    .form-field-container {
      grid-column: span 12;
      justify-content: flex-start;
      label {
        color: mat.get-color-from-palette($et-primary, 600);
        font-weight: var(--font-weight-normal);
        margin-bottom: var(--spacing-4);
      }
      label.label--darker {
        font-size: 14px;
        line-height: 21px;
        color: mat.get-color-from-palette($et-primary, 800);
        margin-bottom: 0;
      }
      label.label--lighter {
        font-size: 14px;
        line-height: 21px;
        color: mat.get-color-from-palette($et-primary, 400);
      }
      mat-radio-group {
        flex-direction: row;
      }
      mat-radio-button, mat-radio-button.mat-radio-button.mat-accent.mat-radio-checked {
        border: none;
        background: initial;
        margin: 0;
      }
      .mat-radio-label-content {
        color: mat.get-color-from-palette($et-primary, 800);
        font-weight: var(--font-weight-regular);
        font-size: var(--font-size-14);
      }
      .mat-radio-outer-circle {
        border-width: 1px;
      }
      mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
        padding: 0.55rem 0;
        border-top: 0;
        font-size: var(--font-size-14);
      }
      .mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix {
        top: 1.5px;
        .mat-icon-button {
          display: flex;
          align-items: flex-end;
          @include square(25px);
        }
        .mat-button-ripple.mat-ripple, .mat-icon-button .mat-button-focus-overlay {
          visibility: hidden;
          opacity: 0;
        }
      }
      //.mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix mat-icon {
      //  @include square(14px);
      //  line-height: 14px;
      //}
      .mat-radio-label {
        padding: var(--spacing-8) var(--spacing-24) var(--spacing-8) 0;
      }
      .mat-form-field.mat-form-field-appearance-outline {
        max-width: none;
      }
      .mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element {
        line-height: 14px;
      }
      .form-field-locked {
        color: mat.get-color-from-palette($et-primary, 300);
        margin-bottom: var(--spacing-24);
        border: 1px solid mat.get-color-from-palette($et-primary, 100);
        padding: var(--spacing-8);
      }
    }
  }
  &__footer {
    display: flex;
    height: var(--modal-footer-height);
    align-items: center;
    justify-content: flex-end;
    padding: 0 var(--spacing-16);
    border-top: var(--et-border);
    background-color: $white-color;
    border-top: var(--et-border);
    border-bottom-left-radius: var(--border-radius-medium);
    border-bottom-right-radius: var(--border-radius-medium);
    button {
      margin-left: var(--spacing-8);
    }
    &--sticky {
      position: fixed;
      bottom: var(--modal-footer-height);
    }
  }
  .base-modal__body-hint{
    margin-bottom: var(--spacing-16);
  }
  .base-modal__body-hint + .base-modal__body-html {
    margin-top: var(--spacing-32);
  }
  &.base-modal-fullscreen {
    .base-modal__header {
      border-bottom: var(--et-border);
      position: fixed;
      z-index: 10;
      width: 100%;
      padding: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      height: 72px;
      display: grid;
      grid-template-columns: var(--spacing-72) var(--spacing-128) 1fr;
      &__close-btn {
        padding: 0 var(--spacing-16);
      }
      &__vertical-line {
        height: 48px;
        width: 1px;
        background-color: mat.get-color-from-palette($et-primary, 100);
        margin-top: var(--spacing-12);
        margin-bottom: var(--spacing-12);
      }
      &__title {
        display: flex;
        justify-content: space-between;
        h4 {
          display: flex;
          align-items: center;
          font-size: var(--font-size-18);
          font-weight: var(--font-weight-regular);
          i {
            @include thumbnail(1.5rem);
            border-radius: 0;
            margin-right: var(--spacing-16);
          }
        }
      }
      &__centered-avatar {
        position: absolute;
        top: var(--spacing-24);
        left: calc(50% - 2.5rem);
      }
      mat-icon {
        color: mat.get-color-from-palette($et-primary, 300);
        height: 18px;
        line-height: 16px;
      }
    }
    .base-modal__body {
      //padding: var(--spacing-72) var(--spacing-192); // padding is different between the different steps
      padding: 0 var(--spacing-192);
      margin: var(--spacing-72) 0 0 0;
      @media (max-width: $layout-breakpoint-md-minus-1) {
        /*padding: var(--spacing-64) var(--spacing-96);
        margin: var(--spacing-64) 0 0 0;*/
      }
    }
  }
}
.base-modal-container-fixed-small {
  .base-modal__header--sticky {
    width: var(--modal-width-fixed-sm);
    max-width: var(--modal-width-fixed-sm);
  }
  .base-modal__footer{
    max-width: var(--modal-width-fixed-sm);
  }
  .base-modal__body--with-sticky-footer {
    height: calc(var(--modal-height-fixed-sm) - var(--modal-header-height) - var(--modal-footer-height));
  }
}
//BUSY OVERLAY
.busy-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: mat.get-color-from-palette($et-primary, 100);
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  z-index: 1000;
  user-select: none;
}
//UNAUTHENTICATED-AREA
.unauth-area {
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  &__form-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    overflow-y: auto;
    place-items: center;
    max-height: 100vh;
    padding: var(--spacing-128) var(--spacing-256);
    @media (max-width: $layout-breakpoint-lg-minus-1) {
      padding: var(--spacing-72) var(--spacing-192);
    }
    @media (max-width: $layout-breakpoint-md) {
      padding: var(--spacing-64);
    }
    label {
      margin-bottom: var(--spacing-4);
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    min-width: $max-form-width;
    h1 {
      margin-bottom: var(--spacing-32);
    }
    a {
      align-self: flex-start;
    }
    .divider {
      margin: var(--spacing-32) 0;
    }
  }
}
//STATUS LABEL
.status-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing-4) var(--spacing-10);
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 20px;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-12);
  line-height: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.87);
  mat-icon {
    margin-right: var(--spacing-10);
    height: 18px;
    width: 18px;
    line-height: 18px;
  }
  &--not-valid {
    background-color: mat.get-color-from-palette($et-error, 300);
  }
  &--valid {
    background-color: mat.get-color-from-palette($et-success, 500);
  }
}
//backdrop overlay
.app-backdrop-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0.6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.info-backdrop-overlay {
  //transparent
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.info-overlay-parent {
  position: relative;
}
.info-overlay {
  position: absolute;
  top: 38px;
  right: 0;
  max-width: 150px;
  background: $white-color;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 11;
  p {
    cursor: pointer;
    padding: 6px var(--spacing-16);
    white-space: nowrap;
    &:hover {
      background-color: mat.get-color-from-palette($et-primary, 50);
    }
  }
  .delete-text {
    color: mat.get-color-from-palette($et-error, 500);
  }
}
/**                           **/
/** OVERRIDDEN VENDORS STYLE  **/
/**                           **/
//lonely here
//here the import about what we want to be styled on inner components existing in listed components which need DOM encapsulation to be forced.
@import "deep";

