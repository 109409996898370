@use '@angular/material' as mat;
// Theming variables
$et-primary: (
  50: #F1F3F4,
  100: #E0E0E0,
  200: #919191,
  300: #646464,
  400: #434343,
  500: #222222,
  600: #111111,
  700: #191919,
  800: #141414,
  900: #000000,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);
$et-success: (
  50: #EDF9E9,
  100: #D2EFC8,
  200: #B4E4A3,
  300: #96D97E,
  400: #80D163,
  500: #69C947,
  600: #61C340,
  700: #56BC37,
  800: #4CB52F,
  900: #3BA920,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: rgba(black, 0.87),
    900: white
  )
);
$et-warning: (
  50: #FDFBE1,
  100: #FBF4B5,
  200: #F9ED84,
  300: #F6E652,
  400: #F4E02D,
  500: #F2DB08,
  600: #F0D707,
  700: #EED206,
  800: #ECCD04,
  900: #E8C402,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: rgba(black, 0.87),
    900: rgba(black, 0.87)
  )
);
$et-error: (
  50: #FEF0F0,//hover
  100: #FAD0D0,
  200: #F7B1B1,
  300: #F39191,
  400: #F17A7A,
  500: #EE6262,
  600: #EC5A5A,
  700: #E95050,
  800: #E95050,
  900: #E23434,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: white,
    800: white,
    900: white
  )
);
$white-color: #ffffff;
$labels-color: #8892A0;
$unipd-color: #9B0014;
$link-color: #056DFF;
$layout-brokenpoint: 799px !default;
$layout-breakpoint-md-minus-1: 1023px !default;
$layout-breakpoint-md: 1024px !default;
$layout-breakpoint-md-plus-1: 1025px !default;
$layout-breakpoint-form-readability: 1100px !default;
$layout-breakpoint-ml-minus-1: 1279px !default;
$layout-breakpoint-ml: 1280px !default;
$layout-breakpoint-ml-plus-1: 1281px !default;
$layout-breakpoint-lg-minus-1: 1489px !default;
$layout-breakpoint-lg: 1490px !default;
$layout-breakpoint-xl-minus-1: 1619px !default;
$layout-breakpoint-xl: 1620px !default;
$layout-breakpoint-xxl: 1900px !default;
$spacing-2: 0.125rem;
$spacing-4: 0.25rem;
$spacing-8: 0.5rem;
$spacing-10: 0.625rem;
$spacing-12: 0.75rem;
$spacing-16: 1rem;
$spacing-20: 1.25rem;
$spacing-24: 1.5rem;
$spacing-32: 2rem;
$spacing-40: 2.5rem;
$spacing-48: 3rem;
$spacing-56: 3.5rem;
$spacing-64: 4rem;
$spacing-72: 4.5rem;
$spacing-84: 5.25rem;
$spacing-96: 6rem;
$spacing-128: 8rem;
$spacing-192: 12rem;
$spacing-256: 16rem;
$font-size-12: 0.75rem;
$font-size-14: 0.875rem;
$font-size-16: 1rem;
$font-size-18: 1.125rem;
$font-size-21: 1.313rem;
$font-size-24: 1.5rem;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$et-border: 1px solid mat.get-color-from-palette($et-primary, 100);
$et-border-bold: 2px solid mat.get-color-from-palette($et-primary, 100);
$border-radius-smallest: 2px;
$border-radius-small: 4px;
$border-radius-medium: 8px;
$border-radius-big: 12px;
$border-radius-bigger: 18px;
$border-radius-biggest: 24px;
//components stuff
$modal-width: 90vw;
$modal-width-fixed-sm: 640px;
$modal-height: 70vh;
$modal-height-fixed-sm: 90vh;
$modal-header-height: 60px;
$modal-footer-height: 70px;
$header-height: 90px;
$max-form-width: 368px;
