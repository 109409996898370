@use '@angular/material' as mat;
@import '../../../assets/scss/settings';
@import '../../../assets/scss/mixins';

.filters {

  .mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  input {
    caret-color: mat.get-color-from-palette($et-primary, 600);
  }

  .mat-form-field-flex {
    display: flex;
    align-items: center;
    border-radius: var(--border-radius-small);
    padding: var(--spacing-4) var(--spacing-8) !important;
    margin-top: 0 !important;
    min-width: 170px;
    height: 34px;
  }

  .mat-form-field-suffix {
    top: -2px !important;
  }

  .mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline-start {
    border-radius: 4px 0 0 4px;
  }

  .mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline-end {
    border-radius: 0 4px 4px 0;
  }

  .mat-input-element {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-14);
    line-height: 20px;
    color: mat.get-color-from-palette($et-primary, 600);
  }

  .filter--keyword{
    .mat-form-field-flex{
      width: 270px;
    }
  }

  .filter--dropdown {
    .mat-form-field-hide-placeholder .mat-select-placeholder {
      color: mat.get-color-from-palette($et-primary, 300) !important;
      -webkit-text-fill-color: unset !important;
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-14);
      line-height: 21px;
    }

    .mat-select .mat-select-arrow {
      color: mat.get-color-from-palette($et-primary, 400);
    }

    .mat-select .mat-select-value {
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-14);
      line-height: 20px;
      color: mat.get-color-from-palette($et-primary, 600);
    }
  }

  .mat-icon {
    svg {
      margin-left: 4px;
    }
    line-height: 16px !important;
  }

  .mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element {
    line-height: 16px !important;
  }
}
